const media = {
  phone: '@media (min-width: 375px)',
  tablet: '@media (min-width: 768px)',
  laptop: '@media (min-width: 1024px)',
  desktop: '@media (min-width: 1200px)',
  full: '@media (min-width: 1440px)',
  photoshop: '@media (min-width: 1920px)',
};

export default media;
