import React from 'react';
import LayoutTemplate from '../templates/layoutTemplate';

const IndexPage = () => {
  return (
    <LayoutTemplate>
      <h1>Hello world</h1>
    </LayoutTemplate>
  );
};

export default IndexPage;
